import {
  createRouter,
  createWebHistory
} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import siteConfig from "../siteConfig";

const routes = [{
    /* 首页 */
    path: '/',
    name: 'home',
    meta: {
      title: '首页',
    },
    component: HomeView
  },
  /* 产品与服务 */
  {
    path: '/solution',
    meta: {
      title: '产品与服务',
    },
    name: 'solution',
    component: () => import( /* webpackChunkName: "contactUS" */ '../views/Solution.vue')
  },
  /* 联系我们 */
  {
    path: '/contactUS',
    name: 'contactUS',
    meta: {
      title: '联系我们',
    },
    component: () => import( /* webpackChunkName: "contactUS" */ '../views/ContactUSView.vue')
  },
  /* 关于千家悦 */
  {
    path: '/about',
    name: 'about',
    children: [{
      path: '',
      name: 'AboutView',
      meta: {
        title: '关于千家悦',
      },
      component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue')
    },{
      path: 'history',
      name: 'HistoryView',
      meta: {
        title: '发展经历',
      },
      component: () => import(  '../views/HistoryView.vue'),
    }]
  },
    {
      path: '/404',
      component: () =>
        import('@/views/error-page/404'),
      hidden: true
    },
    {
      path:'/:pathMatch(.*)',
      redirect: '/404',
      hidden: true
    }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

//导航后置守卫，可以在确定导航到目标页面时再更改title
router.afterEach((to, from) => {
  window.document.title = to.meta.title+"-"+siteConfig.title;
 })
export default router