<template>
  <div class="divider" :style="{backgroundColor: background}">
    <span> {{ msg }}</span>
  </div>
</template>
  
  <script>
export default {
  name: "Divider",
  props: {
    msg: String,
    background: String,
  },
};
</script>
  
  
<style lang="scss">
.divider {
  font-size: 26px;
  text-align: center;
  color: #59b390;
  font-weight: 500;
  padding: 30px 0px 30px 0px ;
  font-weight: 800;
  span {
    display: inline-block;
    height: 40px;
    border-bottom: 2px solid #59b390;
  }
}
</style>
  