// zh.js
export default {
  route: {
    home: '首页',
    solution: '产品与服务',
    about: '关于千家悦',
    contactUS: '联系我们',
    qjyBrowser: "千家悦浏览器",
    ott: "OTT内容",
    webui: "互联网UI",
    introduction: "公司简介",
    history: "发展经历",
    tel: "客服电话",
    support: "技术支持",
    sales: "商务联系",
    marketing: "市场合作",
    client: '合作伙伴',
    info: "北京千家悦网络科技有限公司成立于2007年1月,是一家专内开发嵌入式浏览器的软件公司,千家悦的嵌入式浏览器被广泛应用与电视机、机顶盒、10T等各种设备上,是中国最主要的嵌入式浏览器供应商,并且出口到北美、南美、欧洲、非洲、澳洲、中东及东南亚等全球所有主流市场,目前全球累计出货量超过四千万台;",
    slogan1: '十七年专注于浏览器,嵌入式浏览器专家',
    slogan2: '十七年专注于浏览器,嵌入式浏览器专家',
    slogan3: '精彩历程 创新未来',
    serviceField: "服务领域",
    serviceTitle1: "大屏生态解决方案",
    serviceTitle3: "互联网UI开发&优化",
    serviceText1: "电视、机顶盒、工业控制、智能家居、广告机等带显示屏的设备主流的数字电视/机顶盒芯片完成预集成",
    serviceText2: "预先集成海量的覆盖全球主要国家和地区的webapp内容提供一站式OTT多媒体解决方案和支持度广的OpenBrowser",
    serviceText3: "开发周期短,后期维护成本低且可扩展性高;内存小、运行速度快;满足高度定制化的用户体验;",
    homeBar1: '千家悦2007年成立<br>迄今成立17年',
    homeBar2: '终端用户<br>5000万+',
    homeBar3: '出口到全球<br>所有主流国家和地区',
    webTitle1: "WEB系统开发",
    webTitle2: "定制开发",
    webTitle3: "网站优化",
    webTitle4: "API丰富&高扩展性",
    webContents1: "满足高度定制化<br>开发效率高,轻量,大大缩短软件设计的开发难度和开发周期维护成本低,产品更新迭代速度快<br>便捷流畅的操作体验",
    webContents2: "凭借多年在大屏端设备开发交互领域的深厚积累,我们能够迅速将创意转化为现实,高效开发出市场高度认可和接受的产品;",
    webContents3: "凭借多年深耕浏览器内核开发的丰富经验,将其应用到 web 开发中,使web网站降低内存占用,大幅提升流畅度,网站即使运行在低端设备上,也能具备流畅的操作体验！",
    webContents4: "提供简洁易用的web api对接操作系统更加便捷",
    message: "我要留言",
    contacts: "联系人",
    contactInfo: "联系方式",
    needs: "您的需求",
    submit: "提交",
    ottTitle1: '主流App',
    ottTitle2: '电视应用商店',
    ottTitle3: '开放式互联网',
    ottTitle4: '全球通用',
    ottTitle5: '本地热门',
    ottTitle6: '定制化',
    ottConntent1: '预先集成海量App',
    ottConntent2: 'Zeasn、Foxxum、Netrange',
    ottConntent3: '可供大屏幕上随时浏览任何网页,进行观看电影,电视节目,新闻,听音乐,玩在线游戏,观看体育赛事等娱乐活动',
    ottConntent4: '适配度高,内容丰富<br>专为电视优化,体验更好',
    ottConntent5: '提供当地国家的内容媒资<br>最热门网站书签优先顺序在Open browser主页呈现<br>最好看的媒资用最便捷的方式触达',
    ottConntent6: 'Open browser 支持自定义图标包和主题颜色功能',
    ottslogan: "全球OTT内容预先适配",
    browserTitle1: '系统支持',
    browserTitle2: "内存小、运行速度快",
    browserTitle3: "输入法",
    browserTitle4: "操作便捷",
    browserTitle5: '多媒体支持',
    browserContent1: 'Linux&Android',
    browserContent2: "主流的数字电视&机顶盒芯片完成预集成",
    browserContent3: "支持远程输入<br> 可定制皮肤,与目标产品完美融合<br> 目前已经支持超过60种语言",
    browserContent4: "快捷添加书签,保留历史记录;<br>支持USB鼠标,遥控器,远程虚拟遥控器操作方式<br>密码和自动填充,本地加密存储安全又便捷;",
    browserContent5: "适配多种芯片平台和操作系统,提供满足大屏,智慧屏,联网电视,物联网等多种产品需求;",
  }
}