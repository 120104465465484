<template>
  <div class="browser flex-container">
    <div
      class="browser-item"
      v-for="(item,index) in browserInfp"
      :key="index"
    >
      <div class="browser-icon">
        <svg-icon
          :icon-class="it"
          v-for="(it,ind) in item.icon.split(',')"
          :key="ind"
        />
      </div>
      <div style="width: 60%;">
        <div
          class="browser-title"
          v-html="item.title"
        ></div>
        <div
          class="browser-contents"
          v-html="item.content"
        ></div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "Browser",
  data() {
    return {
      browserInfp: [
        {
          title: this.$t("route.browserTitle1"),
          content: this.$t("route.browserContent1"),
          icon: "linux,android",
          item_icn: "",
        },
        {
          title: this.$t("route.browserTitle2"),
          content: this.$t("route.browserContent2"),
          icon: "fast",
          item_icn: "",
        },
        {
          title: this.$t("route.browserTitle3"),
          content: this.$t("route.browserContent3"),
          icon: "keyboard",
          item_icn: "",
        },
        {
          title: this.$t("route.browserTitle4"),
          content: this.$t("route.browserContent4"),
          icon: "use",
          item_icn: "",
        },
        {
          title: this.$t("route.browserTitle5"),
          content: this.$t("route.browserContent5"),
          icon: "media",
          item_icn: "",
        },
      ],
    };
  },
};
</script>
  
  
<style lang="scss" scoped>
.browser {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .browser-item {
    &:hover {
      box-shadow: 0px 0px 8px rgba(53, 54, 102, 0.2);
      transition: 0.3s ease-in;
    }
    &:hover .browser-icon {
      -webkit-transition: all 0.28s ease-in 0.3s;
      transition: all 0.28s ease-in 0.3s;
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      box-shadow: 0px 0px 8px rgba(53, 54, 102, 0.2);
    }
    height: 180px;
    border-radius: 6px;
    transition: 0.3s ease-in;
    padding: 1rem 1rem;
    box-shadow: 1px 2px 6px 1px rgba(53, 54, 102, 0.08);
    background: #fff;
    width: 100%;
    margin-top: 30px;
    align-items: center !important;
    flex-wrap: wrap !important;
    display: flex;
    flex-wrap: wrap;
    .browser-icon {
      background: #fff;
      width: 80px;
      height: 80px;
      margin: auto 20px auto 10px;
      line-height: 80px;
      border-radius: 50%;
      box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.1);
      text-align: center;
      font-size: 40px;
    }
    .browser-title {
      margin-bottom: 10px;
      font-weight: bold;
    }
  }
  @media (min-width: 768px) {
    .browser-item {
      flex: 0 0 48%;
      max-width: 48%;
    }
  }
}
</style>
  