<template>
  <div class="am-scrollspy-inview">
    <img :src="picture">
    <p>{{title}}</p>
    <div class="text">
      {{ text }}
    </div>
  </div>
</template>
    
    <script>
export default {
  name: "ScrollspyInview",
  props: {
    /**
     * 标题
     */
    title: {
      type: String,
      default: "",
    },
    /**
     * 图片
     */
    picture: {
      type: String,
      default: "",
    },
    /**
     * 内容
     */
    text: {
      type: String,
      default: "",
    },
  },
};
</script>
    
    
  <style lang="scss">
.am-scrollspy-inview {
  width:100%;
  height: 32vh;
  position: relative;
  display: block;
  overflow: hidden;
  font-size: 13px;
  line-height: 1.5;
  margin: 10px;
  &:hover img {
    transform: scale(1.2);
  }
  &:hover p {
    height: 0;
  }
  &:hover .text {
    transform: scale(1);
  }
  img {
    width: 100%;
    height: 100%;
    transition: 0.5s;
  }
  p {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 38px;
    line-height: 38px;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    padding: 0px 8px;
    box-sizing: border-box;
    transition: 0.5s;
    margin: 0px;
  }
  .text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.5s;
    background: rgba(86, 111, 144, 0.8);
    color: #ffffff;
    padding: 5px 14px;
    box-sizing: border-box;
    transform: scale(0);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }
}
</style>
    