<template>
  <div class="foot">
    <div class="nav">
        
    </div>
    <div class="copyright">
      北京千家悦网络科技有限公司 版权所有© 2007-2020 京公网安备11010802010094号 <a
        href="http://beian.miit.gov.cn"
        target="_blank"
      >京ICP备07008066号</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Client",
  data() {
    return {};
  },
};
</script>


<style lang="scss">
.foot {
  .copyright {
    width: 100%;
    background: #000;
    text-align: center;
    line-height: 40px;
    color: #505050;
  }
}
</style>
