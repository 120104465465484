<template>
  <div class="grid">
    <div class="icon"><svg-icon :icon-class="icon" /> </div>
    <div class="title" v-html="title"></div>
  </div>
</template>
  
  <script>
export default {
  name: "Grid",
  data() {
    return {};
  },
  props: {
    /**
     * 是否自动轮播
     */
    icon: {
      type: String,
      default: "",
    },
     /**
     * 是否自动轮播
     */
     title: {
      type: String,
      default: "",
    },
  }
};
</script>
  
  
<style lang="scss">
.grid {
  display: flex;
  width: 100%;
  justify-content: center;
  color: #fff;
  font-size: 20px;
  .svg-icon{
    font-size: 50px;
    padding-right: 10px;
  }
}
</style>
  