<template>
  <div class="home">
    <!-- 幻灯片 -->
    <Swiper :list="swiperList"></Swiper>
    <!-- 服务领域 -->
    <Divider :msg="$t('route.serviceField') "></Divider>
    <div class=" flex-container">
      <ScrollspyInview
        v-for="(item,index) in serviceFieldList"
        :key="index"
        class="client-item"
        :title="item.title"
        :picture="item.picture"
        :text="item.text"
      ></ScrollspyInview>
    </div>
    <!--  -->
    <Browser></Browser>
<!--     <div class="home-pro flex-container">

缺内容
    </div> -->
    <!-- 横幅 -->
    <div class="home-bar flex-container">
      <Grid
        v-for="(item,index) in homeBarList"
        :key="index"
        :title="item.title"
        :icon="item.icon"
      ></Grid>
    </div>

   <!--  <AboutInfo></AboutInfo> -->
    <!--   <History></History> -->
    <Client></Client>
  </div>
</template>

<script>
import Client from "@/components/Client.vue";
import AboutInfo from "@/components/About.vue";
import History from "@/components/History.vue";
import Divider from "@/components/Divider.vue";
import Grid from "@/components/Grid.vue";
import Browser from "./solution/Browser.vue";
import Swiper from "@/components/Swiper.vue";
import ScrollspyInview from "@/components/ScrollspyInview.vue";

import one from "@/assets/image/swiper/1575679891790.png";
import two from "@/assets/image/swiper/1579005364714.jpg";
import three from "@/assets/image/swiper/1579005454324.jpg";
import tv from "@/assets/image/tv.png";
import ott from "@/assets/image/ott.png";
import web from "@/assets/image/code.png";

export default {
  name: "HomeView",
  components: {
    Browser,
    Swiper,
    ScrollspyInview,
    Client,
    AboutInfo,
    History,
    Divider,
    Grid
  },
  data() {
    return {
      swiperList: [one, two, three],
      serviceFieldList: [
        {
          title: this.$t("route.serviceTitle1"),
          picture:tv,
          text: this.$t("route.serviceText1"),
        },
        {
          title: this.$t("route.ott"),
          picture: ott,
          text: this.$t("route.serviceText2"),
        },
        {
          title: this.$t("route.serviceTitle3"),
          picture: web,
          text: this.$t("route.serviceText3"),
        },
      ],
      homeBarList: [
        { 
          icon: "company",
          title: this.$t("route.homeBar1"),
        },
        { 
          icon: "user",
          title: this.$t("route.homeBar2"),
        },
        { 
          icon: "global",
          title: this.$t("route.homeBar3"),
        }
      ],
      homeProList:[
        {
          title:"IME输入法",
          content:"IME输入法<br>一款自主研发、广受好评的TV输入法<br>IME输入法是一款千家悦自主研发的输入法，可以作为独立的输入法产品应用到任意系统。该输入法可自定义皮肤，从而极大程度适应系统UI统一界面，与目标产品完美融合，目前已经支持超过54种语言。",
        },
        {
          title:"",
          content:"",
        },
        {
          title:"",
          content:"",
        },
        {
          title:"",
          content:"",
        },
        {
          title:"",
          content:"",
        }
      ]
    };
  },
};
</script>


<style  lang="scss">
.home {
  .home-bar {
    height: 250px;
    background-image: url("@/assets/image/barchart.png");
    background-size:100% 100% ;
    background-position: center;
    .grid{
      margin-top: 20px;
    }
  }

  .home-pro {
    height: 455px;
    background-image: url("@/assets/image/pro.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  @media (min-width: 992px) {
    .home-bar {
    height: 125px;
    background-image: url("@/assets/image/barchart.png");
    background-repeat: no-repeat;
    background-position: center;
    }
  }
}
</style>
