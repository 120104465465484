<template>
  <div class="about-info  flex-container">
    <div class="picture flex-item">
      <img
        src="@/assets/image/1575852514304.jpg"
        alt=""
        srcset=""
      >
    </div>
    <div class="info flex-item">
      {{$t("route.info")}}
    </div>
  </div>
</template>
    
<script>
export default {
  name: "AboutInfo",
  data() {
    return {};
  },
};
</script>
    
  <style lang="scss" scoped>
.info {
  word-break: break-all;
  line-height: 42px;
  display: inline-block;
  width: 600px;
  margin-left: 25px;
  color: #8c8c8c;
  text-indent: 2em;
  font-size: 20px;
}
</style>
    