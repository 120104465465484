// 在 index.js 文件进行配置
import Cookies from 'js-cookie'
import { createI18n } from 'vue-i18n';
import enLocale from './en'
import zhLocale from './zh'

 
// 定义翻译信息： 创建一个名为 messages 的对象，其中包含不同语言的翻译信息。每个语言都由一个键值对表示，包括自定义的应用文本翻译翻译信息。
const messages = {
  en: {
    ...enLocale,
  },
  zh: {
    ...zhLocale,
  }
}
// 确定用户语言偏好： 定义名为 getLanguage 的函数，根据用户选择的语言和浏览器语言设置来确定应该使用的语言。如果用户未选择语言或浏览器语言无匹配项，则默认使用英语。
export function getLanguage() {
  const chooseLanguage = Cookies.get('language')
  if (chooseLanguage) return chooseLanguage
 
  // if has not choose language
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'zh'
}
 
// 创建 Vue I18n 实例： 使用 VueI18n 的构造函数创建一个名为 i18n 的实例。在构造函数中，通过传递当前语言代码和翻译信息来初始化实例。
const i18n = createI18n({
    locale: 'en', // 设置默认语言
    fallbackLocale: 'en', // 设置后备语言
    messages, // 设置信息
  });
 
// 导出 I18n 实例
export default i18n
 