<template>
  <div class="client ">
    <Divider :msg="$t('route.client') "></Divider>
    <ul class="client-box container">
      <li
        v-for="(item,index) in client"
        :key="index"
        class="client-item"
      >
        <img
          :src="item.logo"
          :alt="item.namename"
          srcset=""
        >
      </li>
    </ul>
  </div>
</template>

<script>
import Divider from "./Divider.vue";
import amlogic from "@/assets/image/client/ner-eight.jpg";
import realtek from "@/assets/image/client/ner-five.jpg";
import medintek from "@/assets/image/client/ner-four.jpg";
import novatek from "@/assets/image/client/ner-nine.jpg";
import hisilicon from "@/assets/image/client/ner-one.jpg";
import qualcomm from "@/assets/image/client/ner-seven.jpg";
import st from "@/assets/image/client/ner-six.jpg";
import foxconn from "@/assets/image/client/ner-three.jpg";
import hikeen from "@/assets/image/client/ner-two.jpg";
import foxxum from "@/assets/image/client/Partner-eight.jpg";
import changhong from "@/assets/image/client/Partner-five.jpg";
import tcl from "@/assets/image/client/Partner-four.jpg";
import netrange from "@/assets/image/client/Partner-nine.jpg";
import cvte from "@/assets/image/client/Partner-one.jpg";
import sharp from "@/assets/image/client/Partner-seven.jpg";
import haier from "@/assets/image/client/Partner-six.jpg";
import mstar from "@/assets/image/client/Partner-ten.jpg";
import skyworth from "@/assets/image/client/Partner-three.jpg";
import hisense from "@/assets/image/client/six-six.jpg";
export default {
  name: "Client",
  components: {
    Divider,
  },
  data() {
    return {
      client: [
        {
          name: "",
          logo: skyworth,
        },
        {
          name: "",
          logo: hisense,
        },
        {
          name: "",
          logo: cvte,
        },
        {
          name: "",
          logo: tcl,
        },
        {
          name: "",
          logo: changhong,
        },
        {
          name: "",
          logo: haier,
        },
        {
          name: "",
          logo: sharp,
        },
        {
          name: "",
          logo: foxxum,
        },
        {
          name: "",
          logo: netrange,
        },
        {
          name: "",
          logo: mstar,
        },
        {
          name: "",
          logo: hisilicon,
        },
        {
          name: "",
          logo: hikeen,
        },
        {
          name: "",
          logo: foxconn,
        },
        {
          name: "",
          logo: medintek,
        },
        {
          name: "",
          logo: realtek,
        },
        {
          name: "",
          logo: st,
        },
        {
          name: "",
          logo: qualcomm,
        },
        {
          name: "",
          logo: amlogic,
        },
        {
          name: "",
          logo: novatek,
        } /* ,  {
          name: "",
          logo: ,
        }, */,
      ],
    };
  },
};
</script>
<style lang="scss">
.client {
  .client-box {
    flex-wrap: wrap;
           /* 垂直方向居中对齐 */
           align-items: center;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    .client-item {
      flex-basis: auto;
      max-width: 20%;
      padding: 5px;
  
    }
  }
}
</style>
