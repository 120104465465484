<template>
  <div class="history container">
    <div class="history_timeline">
      <div
        class="year"
        v-for="(item,index) in history"
        :key="index"
      >
        <div class="info">
          <strong>{{ item.year }}</strong>
          <div
            class="month"
            v-for="(item_month,l) in item.content"
            :key="l"
          >
            <em>{{item_month.month}}月<i class="dosh_1"></i></em>
            <div class="text">
              <p>{{item_month.text}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
export default {
  name: "History",
  data() {
    return {
      history: [
        {
          year: "2021",
          content: [{ month: "07", text: "与酷开科技合作开发Coolita Web OS" }],
        },
        {
          year: "2017",
          content: [
            { month: "07", text: "与MTK合作开发PTK电视方案" },
            { month: "08", text: "千家悦与印度卫星电视运营商合作开发OTT项目" },
          ],
        },
        {
          year: "2016",
          content: [{ month: "05", text: "与阿里巴巴合作开发阿里云OS(TV版)" }],
        },
        {
          year: "2013",
          content: [
            { month: "05", text: "千家悦浏览器支持芒果TV的互联网电视标准" },
          ],
        },
        {
          year: "2012",
          content: [
            {
              month: "07",
              text: "千家悦浏览器支持百事通、华数的互联网电视标准",
            },
          ],
        },
        {
          year: "2010",
          content: [
            {
              month: "02",
              text: "与海尔、海信、TCL、康佳、创维、长虹等多家电视机厂家合作开发",
            },
            {
              month: "09",
              text: "海信与千家悦合作发布全球第一款带开放式浏览器的互联网电视",
            },
          ],
        },
        {
          year: "2009",
          content: [
            {
              month: "05",
              text: "千家悦浏览器成功应用于一汽奔腾B50车载导航设备",
            },
            { month: "10", text: "与世纪互联、CCTV合作开发数字电视机顶盒" },
            { month: "12", text: "与海尔电视合作，开发海尔摩卡电视" },
          ],
        },
        {
          year: "2008",
          content: [
            {
              month: "12",
              text: "千家悦浏览器成功应用于天宇朗通的手机(MTK feature phone)",
            },
          ],
        },
        { year: "2007", content: [{ month: "01", text: "千家悦公司成立" }] },
      ],
    };
  },
};
</script>
    
    
  <style lang="scss">
.history {
  background-color: $white_color;
  .history_timeline {
    padding: 20px 20px;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .year {
      margin-top: 55px;
      strong {
        color: $theme_C4;
        font-size: 24px;
        margin-bottom: 35px;
        display: block;
        font-weight: 700;
      }
      .month {
        padding-left: 25px;
        color: #b0b0b0;
        position: relative;
        padding-bottom: 10px;
        border-left: 1px solid #e0e0e0;
        margin-left: 62px;
        em {
          font-style: normal;
          position: absolute;
          left: -43px;
          font-size: 14px;
          i {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            margin-left: 8px;
          }
          .dosh_1 {
            background: $theme_C1;
          }
        }
        .text {
          margin-left: 42px;
          width: 40vw;
          p {
            color: $theme_C5;
            font-size: 14px;
            line-height: 1.5;
            margin: 0;
            padding-bottom: 20px;
          }
        }
      }
    }
  }
}
</style>
    