<template>
  <TopNav></TopNav>
  <router-view />

  <Foot></Foot>
</template>

<script>
import TopNav from "@/components/TopNav.vue";
import Foot from "@/components/Foot.vue";


export default {
  name: "App",
  components: {
    TopNav,
    Foot,

  },
};
</script>
<style lang="scss">
</style>
