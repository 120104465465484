<template>
  <header class="site-header">
    <div class="header-container">
      <div class="header-brand">
        <!-- Logo -->
        <a
          class="logo"
          href="//www.qianjiayue.com"
        >
          <img
            src="@/assets/image/logo.png"
            alt=""
            srcset=""
          >
        </a>
        <button
          type="button"
          v-bind:class="[isOpenCollapse?'open':'','nav-toggle']"
          @click="toggleNavigation()"
        >
          <span></span>
        </button>
      </div>

      <div
        class="header-collapse"
        v-bind:style="[isOpenCollapse?'display: block;':'']"
      >
        <div class="header-collapse-inner">
          <nav class="site-nav">
            <ul id="navigation">
              <li
                v-for="parent in navList"
                :key="parent.name"
              >
                <router-link
                  :to="parent.url"
                  active-class="active"
                >{{ parent.name }}</router-link>
                <ul
                  v-if="parent.children"
                  class="subnav"
                >
                  <li
                    v-for="child in parent.children"
                    :key="child.name"
                  >
                    <router-link
                      active-class="active"
                      :to="child.url"
                    >{{ child.name }}
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div style="height: 85px;"></div>
  </header>
</template>
<script>
export default {
  name: "TopNav",
  data() {
    return {
      navList: [
        {
          url: "/",
          name: this.$t("route.home"),
          children: null,
        },
        {
          url: "/solution",
          name: this.$t("route.solution"),
          children: [
            {
              url: { path: "/solution", hash: "#browser" },
              name: this.$t("route.qjyBrowser"),
            },
            {
              url: { path: "/solution", hash: "#ott" },
              name: this.$t("route.ott"),
            },
            {
              url: { path: "/solution", hash: "#web" },
              name: this.$t("route.webui"),
            },
          ],
        },
        {
          url: "/about",
          name: this.$t("route.about"),
          children: [
            { url: "/about", name: this.$t("route.introduction") },
            { url: "/about/history", name: this.$t("route.history") },
          ],
        },
        {
          url: "/contactUS",
          name: this.$t("route.contactUS"),
          children: null,
        },
      ],
      isOpenCollapse: false,
    };
  },
  methods: {
    toggleNavigation() {
      this.isOpenCollapse = !this.isOpenCollapse;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header-container {
  background-color: $theme_B1;
  padding: 10px 40px 10px 40px;
  position: fixed;
  z-index: 10;
  width: 100%;
  .header-brand {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    .logo {
      display: table;
      margin-left: 0;
      img {
        display: block;
        width: 200px;
      }
    }
    .nav-toggle {
      display: block;
      position: relative;
      border: 0;
      margin-left: auto;
      margin-right: 0;
      padding: 0;
      background: transparent;
      width: 32px;
      height: 32px;
      outline: none;
      cursor: pointer;
      &:focus {
        outline: none;
      }
      span {
        position: relative;
        width: 30px;
        margin: 0 auto;
        background: $theme_C2;
        -webkit-transition: background 0.2s 0.2s ease-in-out;
        -moz-transition: background 0.2s 0.2s ease-in-out;
        -o-transition: background 0.2s 0.2s ease-in-out;
        transition: background 0.2s 0.2s ease-in-out;
        &,
        &::before,
        &::after {
          display: block;
          height: 2px;
          -webkit-background-clip: padding-box;
          -moz-background-clip: padding;
          background-clip: padding-box;
        }
        &::before,
        &::after {
          position: absolute;
          content: "";
          width: 15px;
          background: $theme_C2;
          -webkit-transform-origin: 50% 50%;
          -moz-transform-origin: 50% 50%;
          -o-transform-origin: 50% 50%;
          -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
          -webkit-transition: top 0.2s 0.4s ease-in-out,
            left 0.2s 0.2s ease-in-out, right 0.2s 0.2s ease-in-out,
            width 0.2s 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
          -moz-transition: top 0.2s 0.4s ease-in-out, left 0.2s 0.2s ease-in-out,
            right 0.2s 0.2s ease-in-out, width 0.2s 0.2s ease-in-out,
            -moz-transform 0.2s ease-in-out;
          -o-transition: top 0.2s 0.4s ease-in-out, left 0.2s 0.2s ease-in-out,
            right 0.2s 0.2s ease-in-out, width 0.2s 0.2s ease-in-out,
            -o-transform 0.2s ease-in-out;
          transition: top 0.2s 0.4s ease-in-out, left 0.2s 0.2s ease-in-out,
            right 0.2s 0.2s ease-in-out, width 0.2s 0.2s ease-in-out,
            transform 0.2s ease-in-out;
        }
        &:before {
          top: 10px;
          left: 15px;
        }

        &:after {
          top: -10px;
          right: 15px;
        }
      }
    }
    .open {
      span {
        background: transparent !important;
        -webkit-transition: background 0.2s 0s ease-in-out;
        -moz-transition: background 0.2s 0s ease-in-out;
        -o-transition: background 0.2s 0s ease-in-out;
        transition: background 0.2s 0s ease-in-out;

        &::after,
        &::before {
          top: 0;
          width: 30px;
          -webkit-transition: top 0.2s ease-in-out, left 0.2s 0.2s ease-in-out,
            right 0.2s 0.2s ease-in-out, width 0.2s 0.2s ease-in-out,
            -webkit-transform 0.2s 0.4s ease-in-out;
          -moz-transition: top 0.2s ease-in-out, left 0.2s 0.2s ease-in-out,
            right 0.2s 0.2s ease-in-out, width 0.2s 0.2s ease-in-out,
            -moz-transform 0.2s 0.4s ease-in-out;
          -o-transition: top 0.2s ease-in-out, left 0.2s 0.2s ease-in-out,
            right 0.2s 0.2s ease-in-out, width 0.2s 0.2s ease-in-out,
            -o-transform 0.2s 0.4s ease-in-out;
          transition: top 0.2s ease-in-out, left 0.2s 0.2s ease-in-out,
            right 0.2s 0.2s ease-in-out, width 0.2s 0.2s ease-in-out,
            transform 0.2s 0.4s ease-in-out;
        }

        &::before {
          left: 0;
          -webkit-transform: rotate3d(0, 0, 1, 45deg);
          -moz-transform: rotate3d(0, 0, 1, 45deg);
          -o-transform: rotate3d(0, 0, 1, 45deg);
          -ms-transform: rotate3d(0, 0, 1, 45deg);
          transform: rotate3d(0, 0, 1, 45deg);
        }

        &::after {
          right: 0;
          -webkit-transform: rotate3d(0, 0, 1, -45deg);
          -moz-transform: rotate3d(0, 0, 1, -45deg);
          -o-transform: rotate3d(0, 0, 1, -45deg);
          -ms-transform: rotate3d(0, 0, 1, -45deg);
          transform: rotate3d(0, 0, 1, -45deg);
        }
      }
    }
  }
  .header-collapse {
    display: none;
    width: 100%;
    .header-collapse-inner {
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      .site-nav {
        ul {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          padding-left: 0;
          margin-bottom: 0;
          list-style: none;
          > li {
            &:hover > a,
            &:active > a {
              border-bottom: 2px solid $theme_C1;
            }
            &:hover .subnav,
            &:active .subnav {
              opacity: 1;
              transform: translate3d(0, 0, 0);
              visibility: visible;
            }
            a {
              font-family: "Open Sans", sans-serif;
              font-size: 18px;
              line-height: 3;
              color: $theme_C2;
              position: relative;
              border-bottom: 2px solid transparent;
              margin-top: 0;
              margin-bottom: 0;
              padding-top: 5px;
              padding-bottom: 5px;
              height: 65px;
            }

            .subnav {
              position: absolute;
              background: $theme_B1;
              border-bottom: 0;
              left:45%;
              z-index: 9999;
              opacity: 0;
              transition: all 0.3s ease 0s;
              visibility: hidden;
              transform: translate3d(0, 10px, 0);
              width: 180px;
              top: 90px;
              &:after {
                content: "";
                display: none;
              }
              a {
                color: $white_color;
                display: block;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 992px) {
    .header-brand {
      width: auto;
      display: inline-block;
      .nav-toggle {
        display: none;
      }
    }
    .header-collapse {
      display: block;
      margin-left: auto;
      margin-right: 0;
      width: auto;
      float: right;
      .header-collapse-inner {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        .site-nav {
          margin-top: 0;
          position: relative;
          > ul {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            align-items: center;
            height: 65px;
            > li {
              margin-left: 30px;
              margin-right: 30px;
              font-weight: 400;
              text-align: center;
              position: relative;
              .subnav {
                border-top: 2px solid $white_color;
                background: $white_color;
                border: 1px solid $theme_B0;
                left: -50%;
                top: 60px;
                &:after {
                  content: "";
                  display: block;
                  border-left: 10px solid transparent;
                  border-right: 10px solid transparent;
                  border-bottom: 10px solid $white_color;
                  position: absolute;
                  top: -12px;
                  right: 46%;
                }
                a {
                  color: #333;
                  &:hover {
                    background: $theme_B0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
