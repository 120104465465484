import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './nls'
import '@/assets/styles/themes.scss'
import './icons' // icon
import SvgIcon from '@/components/SvgIcon'// svg component

createApp(App).component('svg-icon', SvgIcon).use(store).use(router).use(i18n).mount('#app')
